<template lang="pug">
v-container(max-width="960")
  v-row
    v-col(cols="12")
      v-card
        v-card-title Редактирование конфигурации терминала
        template(v-if="!isReady")
          v-card-text.pb-5
            spinner
        template(v-else)
          v-stepper.mt-5#edit-form(
          )
            v-stepper-header
              template(v-for="(step, idx) in schemas")
                v-stepper-step(
                  :step="step.id"
                  editable
                  :rules="[() => !!step.valid]"
                  :key="`${step.id}-step`"
                ) {{step.name}}
                v-divider(v-if="(idx + 1) !== schemas.length")

            v-stepper-items
              v-stepper-content.pa-5(
                v-for="step in schemas"
                :step="step.id"
                :key="`${step.id}-step`"
              )
                form-generator(
                  v-model="config"
                  :name="`form_${step.id}`"
                  :schema="step.schema"
                  :ref="`form_${step.id}`"
                )

  v-row.pt-5(v-show="isReady" justify="end")
    v-col(cols="2")
      v-btn(
        outlined
        dence
        color="blue"
        large
        block
        @click="onAbort"
      ) Назад
    v-col(cols="2")
      v-btn.white--text(
        color="blue"
        large
        block
        @click="onUpdate"
      ) Сохранить
</template>

<script>
import { mapGetters } from 'vuex';
import { mapGettersMutations } from '@/utils/vuex';
import { debounce } from 'lodash';

export default {
  name: 'TerminalEdit',

  components: {
    EditJson: () => import('@/components/terminals/edit-json/index.vue'),
    Spinner: () => import('@/components/Spinner.vue'),
  },

  beforeRouteLeave(to, from, next) {
    this.$store.dispatch('TERMINAL_UPDATE/reset');
    next();
  },

  computed: {
    ...mapGettersMutations('TERMINAL_UPDATE', {
      config: 'config',
    }),

    ...mapGetters('TERMINAL_UPDATE', ['schemas', 'isReady']),
  },

  methods: {
    validateAll() {
      return this.schemas
        .map((schema, idx) => {
          if (
            this.$refs[`form_${schema.id}`] &&
            this.$refs[`form_${schema.id}`][0].validate
          ) {
            this.$refs[`form_${schema.id}`][0].reset();
            const validStatus = this.$refs[`form_${schema.id}`][0].validate();

            this.$store.commit(`TERMINAL_UPDATE/schemaValidateStatus`, {
              idx,
              validStatus,
            });

            return validStatus;
          }
        })
        .every(valid => typeof valid === 'boolean' && valid);
    },

    onUpdate: debounce.call(
      this,
      async function (value) {
        if (!this.validateAll()) {
          this.$vuetify.goTo('#edit-form');
          this.$notify({
            group: 'error',
            type: 'error',
            title: `Ошибка. Конфигурация терминала заполнена не правильно.`,
            text: '',
          });
          return null;
        }

        this.$store
          .dispatch('TERMINAL_UPDATE/update')
          .then(() => {
            this.$notify({
              group: 'note',
              type: 'info',
              title: 'Конфигурация терминала обновлена.',
              text: '',
            });
          })
          .catch(() => {
            this.$notify({
              group: 'error',
              type: 'error',
              title: `Произошла ошибка обновления конфигурации терминала. Попробуйте позже.`,
              text: '',
            });
          });
      },
      1000,
    ),

    onAbort() {
      this.$store.dispatch('TERMINAL_UPDATE/reset');
      this.$router.back();
    },
  },
};
</script>
